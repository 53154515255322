<template>
    <b-modal
        :id="name"
        ref="address-modal"
        title="IP-Adresse bestellen"
        :hide-footer="true"
        back
        size="xl"
    >
        <p>Neue IPv4 Adresse wirklich bestellen?</p>
        <hr>
        <div class="text-center">
            <b-button variant="primary" class="w-50" @click="create()">
                Bestellen
            </b-button>
        </div>
    </b-modal>
</template>

<script>
import { IpAddress } from '@/core/services/store/models/ip_address'
import projectMixin from '@/core/mixins/projectMixin'

export default {
    name: 'AddressCreateModal',
    mixins: [projectMixin],
    props: {
        name: {
            type: String,
            required: true
        }
    },
    methods: {
        async create() {
            this.$refs['address-modal'].hide()
            const res = await this.api.createAddress({
                project_uuid: this.project.uuid
            })
            console.log(res)
            await IpAddress.insert({
                data: [res.data]
            })
        }
    }
}
</script>

<style scoped>

</style>
