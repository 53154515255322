<template>
    <div>
        <div class="input-group mb-3 input-group-sm">
            <input v-model="filter" type="text" class="form-control" placeholder="Filter...">
            <div class="input-group-append">
                <span id="basic-addon2" class="input-group-text"><b-icon-filter /></span>
            </div>
        </div>
        <hr>
        <router-link
            tag="div"
            class="w-100 mb-1 btn-product"
            :class="{ active: $route.name === 'projects-single' }"
            :to="{ name: 'projects-single', params: { uuid: project.uuid } }"
        >
            Projektübersicht
        </router-link>
        <hr>
        <ProductListCategory
            v-for="p of products"
            :key="p.model.entity"
            :title="p.title"
            :filter="filter"
            :display="p.display"
            :project="project"
            :product="p.model"
            :modal="p.create"
        />
        <hr>
        <CloudServerCreateModal name="cloud-server-create-modal" />
        <AddressCreateModal name="address-create-modal" />
        <FirewallAddressSetCreateModal name="firewall-address-set-create-modal" />
        <FirewallRuleSetCreateModal name="firewall-rule-set-create-modal" />
    </div>
</template>s

<script>
import ProductListCategory from '@/view/components/layout/ProductListCategory'
import { CloudServer } from '@/core/services/store/models/cloud_server'
import { Network } from '@/core/services/store/models/network'
import { IpAddress } from '@/core/services/store/models/ip_address'
import { FirewallRuleSet } from '@/core/services/store/models/firewall_rule_set'
import { FirewallAddressSet } from '@/core/services/store/models/firewall_address_set'
import { Project } from '@/core/services/store/models/project'
import CloudServerCreateModal from '@/view/components/cloudserver/modals/CloudServerCreateModal'
import AddressCreateModal from '@/view/components/addresses/modals/AddressCreateModal'
import FirewallAddressSetCreateModal
    from '@/view/components/firewall_address_sets/modals/FirewallAddressSetCreateModal'
import FirewallRuleSetCreateModal from '@/view/components/firewall_rule_sets/modals/FirewallRuleSetCreateModal'
export default {
    name: 'ProductList',
    components: {
        FirewallRuleSetCreateModal,
        FirewallAddressSetCreateModal,
        AddressCreateModal,
        CloudServerCreateModal,
        ProductListCategory },
    props: {
        project: {
            type: Project,
            required: true,
            default: null
        }
    },
    data() {
        return {
            filter: '',
            products: [
                {
                    title: 'Cloud Server',
                    model: CloudServer,
                    display: server => server.id + ' (' + (server.title || server.hostname || 'Unbenannt') + ')',
                    create: 'cloud-server-create-modal'
                },
                {
                    title: 'Netzwerke',
                    model: Network,
                    display: network => network.id
                },
                {
                    title: 'IP-Adressen',
                    model: IpAddress,
                    display: address => address.address,
                    create: 'address-create-modal'
                },
                {
                    title: 'Firewall Regelsets',
                    model: FirewallRuleSet,
                    display: set => set.title,
                    create: 'firewall-rule-set-create-modal'
                },
                {
                    title: 'Firewall Adresssets',
                    model: FirewallAddressSet,
                    display: set => set.title,
                    create: 'firewall-address-set-create-modal'
                }
            ]
        }
    },
    computed: {
        selected() {
            return {
                type: this.$route.meta.productType,
                id: this.$route.params.id
            }
        }
    },
    methods: {
        showAdd(type) {
            this.$emit('add', type)
        }
    },
    created() {
        CloudServer.fetchAll()
        IpAddress.fetchAll(this.project.uuid)
        FirewallAddressSet.fetchAll()
        FirewallRuleSet.fetchAll()
    }
}
</script>

<style scoped>

</style>
