<template>
    <b-modal
        :id="name"
        title="Firewall Regelset erstellen"
        :hide-footer="true"
        back
        size="xl"
    />
</template>

<script>
export default {
    name: 'FirewallRuleSetCreateModal',
    props: {
        name: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
