<template>
    <b-list-group class="rounded-0 h-100">
        <b-list-group-item class="p-0 py-2 border-0 text-center">
            <b-button
                v-b-tooltip="{ placement: 'right', interactive: false, trigger: 'hover' }"
                variant="link"
                class="p-0 border-0 d-inline-block m-auto"
                title="Zur Startseite"
                :to="{ name: 'overview' }"
            >
                <b-icon-house style="color: rgb(63, 66, 84); width: 40px; height: 40px;" />
            </b-button>
        </b-list-group-item>
        <hr style="width: 75%; margin: 0 auto 0px auto; padding: 0px;">
        <b-list-group-item class="p-0 py-2 border-0 text-center">
            <router-link
                v-b-tooltip="{ placement: 'right', interactive: false, trigger: 'hover' }"
                variant="link"
                class="p-0 border-0 d-inline-block m-auto"
                title="Zur Kundenübersicht"
                :to="customer ? { name: 'customer-dashboard', params: { customer_id: customer.id }} : { name: 'overview' }"
            >
                <b-icon-building style="color: rgb(63, 66, 84); width: 40px; height: 40px;" />
            </router-link>
        </b-list-group-item>
        <b-list-group-item v-for="project in customerProjects"
                           :key="project.uuid"
                           class="p-2 border-0"
        >
            <b-button
                v-b-tooltip="{ placement: 'right', interactive: false, trigger: 'hover' }"
                variant="link"
                :class="{ 'p-0': true, 'border-primary': isCurrentProject(project), 'border-2': true}"
                :title="project.title"
                :to="{ name: 'projects-single', params: { uuid: project.uuid } }"
            >
                <span
                    class="symbol symbol-45 m-0"
                    style="color: #9D9B9B"
                >
                    <span class="symbol-label font-size-h5 font-weight-bold">
                        {{ project && project.title && project.title[0] }}
                    </span>
                </span>
            </b-button>
        </b-list-group-item>
        <b-list-group-item class="p-2 border-0">
            <b-button
                v-b-tooltip.hover
                v-b-tooltip.right
                variant="link"
                class="p-0 border-0"
                title="Projekt erstellen"
                @click="openProjectCreation"
            >
                <b-icon-plus style="width: 45px; height: 45px; color: gray;" />
            </b-button>
        </b-list-group-item>
    </b-list-group>
</template>

<script>
import { Project } from '@/core/services/store/models/project'
import customerMixin from '@/core/mixins/customerMixin'
import projectMixin from '@/core/mixins/projectMixin'

export default {
    name: 'ProjectSidebar',
    mixins: [customerMixin, projectMixin],
    methods: {
        /**
         * Checks if the project is the same as currentProject
         * @param project that should be checked for equality
         * @returns {boolean}
         */
        isCurrentProject (project) {
            if (project == null || this.project == null) return false

            return project.uuid === this.project.uuid
        },
        /**
         * Opens the project creation popup and creates project if confirmed
         */
        async openProjectCreation () {
            const result = await this.$swal({
                title: 'Neues Projekt erstellen',
                input: 'text',
                showCancelButton: true,
                confirmButtonText: 'Projekt erstellen'
            })
            if (result.isConfirmed) {
                const response = await this.api.createProject({
                    title: result.value,
                    customer_id: this.customer.id
                })
                await Project.insert(response)
                await this.$router.push({ name: 'projects-single', params: { uuid: response.data.uuid } })
            }
        }
    }
}
</script>
