<template>
    <div class="mb-1">
        <span class="category-title font-weight-bolder" @click="collapsed = !collapsed"><b-icon-chevron-right v-if="collapsed" /><b-icon-chevron-down v-else /> <span>{{ title }}</span></span>
        <b-icon-plus v-if="modal" v-b-modal="modal" class="btn-add float-right pt-1" style="width: 20px; height: 20px;" />
        <div v-if="!collapsed" class="mt-1">
            <router-link
                v-for="p of products"
                :key="p.id"
                tag="div"
                class="w-100 mb-1 btn-product"
                :class="{ active: $route.meta.entity.entity === product.entity && $route.params[product.primaryKey].toString() === p[product.primaryKey].toString() }"
                :to="{ name: product.route, params: { [product.primaryKey]: p[product.primaryKey] }}"
            >
                {{ display(p) }}
            </router-link>
            <div v-if="products.length === 0">
                <b-icon /> <span style="font-size: 0.8em;">Keine Elemente vorhanden</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Project } from '@/core/services/store/models/project'

export default {
    name: 'ProductListCategory',
    props: {
        title: {
            type: String,
            required: true
        },
        filter: {
            type: String,
            required: true
        },
        product: {
            type: Function,
            required: true
        },
        project: {
            type: Project,
            required: true
        },
        display: {
            type: Function,
            required: false,
            default: p => p.id
        },
        modal: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            collapsed: this.$route.meta.entity.entity !== this.product.entity
        }
    },
    computed: {
        products() {
            return this.product.filter(this.filter, this.project.uuid).get()
        }
    }
}
</script>

<style scoped lang="scss">
    @import "src/assets/sass/components/variables.bootstrap";
    .category-title {
        cursor: pointer;
        user-select: none;
        text-transform: uppercase;
        span {
            font-size: 13px;
        }
    }
    .btn-add {
        display: inline;
        cursor: pointer;
        &:hover {
            color: $primary
        }
    }
</style>
