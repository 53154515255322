<template>
    <b-modal
        :id="name"
        title="Firewall Adressset erstellen"
        :hide-footer="true"
        back
        size="xl"
    >
        <b-form @submit.prevent="onSubmit">
            <b-form-group label="Titel">
                <b-input v-model="title" placeholder="Titel eingeben" />
            </b-form-group>
            <b-button variant="primary" type="submit">
                Erstellen
            </b-button>
        </b-form>
    </b-modal>
</template>

<script>
import projectMixin from '@/core/mixins/projectMixin'
import { FirewallAddressSet } from '@/core/services/store/models/firewall_address_set'

export default {
    name: 'FirewallAddressSetCreateModal',
    mixins: [projectMixin],
    props: {
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            title: ''
        }
    },
    methods: {
        async onSubmit () {
            const result = await this.api.createFirewallAddressSet({
                title: this.title,
                project_uuid: this.project.uuid
            })
            await FirewallAddressSet.insert(result)
            this.$bvModal.hide(this.name)
            await this.$router.push({ name: 'firewall-address-sets-single', params: { id: result.data.id } })
            this.title = ''
        }
    }
}
</script>

<style scoped>

</style>
