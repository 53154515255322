<template>
    <form
        @submit.prevent="$emit('confirm')"
        @change="change"
        @click="change"
        @keydown="change"
    >
        <h4>Allgemeine Konfiguration</h4>
        <b-row cols="2">
            <b-col v-for="(option, index) in ['cores', 'memory']"
                   :key="index"
                   class="mb-10"
            >
                <div v-if="Object.values(options).length > 0" class="card card-custom card-stretch">
                    <div class="card-body pb-3 pt-3">
                        <SliderEdit v-model="value[option]"
                                    :step="options[option].step"
                                    :min="options[option].min_units"
                                    :max="options[option].max_units"
                                    :name="option"
                                    :title="$tc(`DATACENTER.VSERVERS.CONFIGURATION.${option.toUpperCase()}`, 2)"
                        />
                    </div>
                </div>
            </b-col>
            <b-col class="mb-10">
                <div class="card card-custom card-stretch">
                    <div class="card-body pb-3 pt-5">
                        <h6>Betriebssystem</h6>
                        <b-form-select
                            :value="value.template"
                            :options="templates.map(t => ({ text: t.title, value: t.key }))"
                            class="mb-3 mt-3"
                            @change="value => { value.template = value.toString() }"
                        />
                    </div>
                </div>
            </b-col>
            <b-col class="mb-10">
                <div class="card card-custom card-stretch">
                    <div class="card-body pb-3 pt-5">
                        <h6>Hostname</h6>
                        <b-form-input
                            :value="value.hostname"
                            class="mb-3 mt-3"
                            placeholder="www.example.com"
                            @change="value => { value.hostname = value.toString() }"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>

        <h4>{{ $t('DATACENTER.VSERVERS.CONFIGURATION.DISKS.DESC', {n: value.storages.length, max: 5}) }}</h4>
        <div v-if="options['disk'] !== undefined" class="row mb-10 position-relative">
            <div v-for="(storage, index) in value.storages"
                 :key="index"
                 class="col-md-4 mt-3"
            >
                <div class="card card-custom card-stretch">
                    <div class="card-body pb-3 pt-3">
                        <SliderEdit
                            v-model="storage.size"
                            name="disk"
                            :step="options['disk'].step"
                            :min="options['disk'].min_units"
                            :max="options['disk'].max_units"
                            :title="$t('DATACENTER.VSERVERS.CONFIGURATION.DISKS.ONE', {n: (index+1)})"
                        />

                        <div class="mt-2 text-center font-italic">
                            <b-button
                                v-if="value.storages.length > 1"
                                variant="link"
                                size="sm"
                                tabindex="-1"
                                style="position: absolute; right: -20px; top: -20px;"
                                class="text-decoration-none"
                                @click="value.storages.splice(index, 1)"
                            >
                                <span style="width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; border-radius: 50%; background: rgba(0, 0, 0, .5); font-size: 1.5em;">
                                    <b-icon-x style="fill: white;" />
                                </span>
                                <!--                                (entfernen)-->
                            </b-button>

                            <p v-if="!storage.title" class="text-center">
                                Neue Disk
                            </p>
                            <p v-if="isStorageEdited(storage)" class="text-center">
                                Geändert
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3">
                <div v-if="value.storages.length < 5"
                     class="card card-custom card-stretch opacity-50 hover-opacity-80 cursor-pointer"
                     style="min-height: 130px"
                     @click="addStorage"
                >
                    <div class="text-center"
                         style="position: absolute;display: inline;left:50%;top: 50%;transform: translate(-50%,-50%); font-size: 50px;"
                    >
                        <b-icon-plus />
                    </div>
                </div>
            </div>
        </div>

        <h4>{{ $t('DATACENTER.VSERVERS.CONFIGURATION.INTERFACES.DESC', {n: value.interfaces.length, max: 10}) }}</h4>
        <div class="row mb-10 position-relative">
            <div v-for="(network, index) in value.interfaces"
                 :key="index"
                 class="col-md-4 mt-3"
            >
                <div class="card card-custom card-stretch">
                    <div class="card-body">
                        <b-form-radio-group
                            id="btn-radios-2"
                            v-model="network.is_public"
                            buttons
                            button-variant="outline-primary"
                            size="sm"
                            name="radio-btn-outline"
                            :options="[{ text: 'Internet', value: true },{ text: $t('DATACENTER.VSERVERS.CONFIGURATION.INTERFACES.PRIVATE_NETWORK'), value: false }]"
                            class="mb-10 d-flex"
                            @change="network.network_id = null; network.primary_ipv4 = undefined; network.custom_address = undefined; network.custom_gateway = undefined;"
                        />

                        <div v-if="!network.is_public">
                            <label>Netzwerk</label>
                            <b-form-select
                                v-if="!toggleNetworkList"
                                :value="network.network_id"
                                :label="$t('DATACENTER.VSERVERS.CONFIGURATION.INTERFACES.CHOOSE_NETWORK')"
                                :options="networks.map(n => ({ value: n.id, text: n.title + (n.id ? ' (ID: ' + n.id + ')' : '') }))"
                                @change="value => { network.network_id = parseInt(value) }"
                            />
                        </div>

                        <div class="">
                            <template v-if="network.is_public">
                                <label>Adresse</label>
                                <b-form-select
                                    ref="massSelect"
                                    :value="network.primary_ipv4"
                                    clearable
                                    :options="freeAddresses.map(a => ({ value: a.address, text: a.address }))"
                                    label="IP auswählen"
                                    @change="elem => { network.network_id = freeAddresses.find(e => e.address === elem).vlan_id; network.primary_ipv4 = elem }"
                                />
                            </template>
                            <template v-else-if="network.is_public === false">
                                <label>Adresse</label>
                                <b-form-input
                                    v-model="network.custom_address"
                                    :label="$t('DATACENTER.VSERVERS.CONFIGURATION.NETWORKS.IP_ADDRESS')"
                                    placeholder="10.12.12.5/24"
                                />
                            </template>
                        </div>

                        <div class="mt-2 text-center font-italic">
                            <b-button
                                variant="link"
                                size="sm"
                                style="position: absolute; right: -20px; top: -20px;"
                                tabindex="-1"
                                class="text-decoration-none"
                                @click="value.interfaces.splice(index, 1)"
                            >
                                <span style="width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; border-radius: 50%; background: rgba(0, 0, 0, .5); font-size: 1.5em;">
                                    <b-icon-x style="fill: white;" />
                                </span>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3">
                <div v-if="value.interfaces.length < 10"
                     class="card card-custom card-stretch opacity-50 hover-opacity-80 cursor-pointer"
                     style="min-height: 130px"
                     @click="addInterface"
                >
                    <div class="text-center"
                         style="position: absolute;display: inline;top: 50%;left:50%;transform: translate(-50%,-50%); font-size: 50px;"
                    >
                        <b-icon-plus />
                    </div>
                </div>
            </div>
        </div>

        <div class="float-left">
            <b-button
                variant="primary"
                type="submit"
                size="lg"
                :disabled="$wait.is('vserver-create')"
            >
                <i v-if="$wait.is('vserver-create')"
                   class="fa fa-spinner fa-spin"
                />

                {{ submitTitle }}
            </b-button>
        </div>
        <div class="float-right h3">
            {{ $t('DATACENTER.VSERVERS.PER_DAY') }}: {{ (Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price/30)) }}<br>
            {{ $t('DATACENTER.VSERVERS.PER_MONTH') }}: {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price) }}
        </div>
    </form>
</template>

<script>
import FormatValues from '@/core/mixins/formatValues'
import SliderEdit from '@/view/components/helper/SliderEdit'
import { IpAddress } from '@/core/services/store/models/ip_address'
import { Network } from '@/core/services/store/models/network'
import projectMixin from '@/core/mixins/projectMixin'
import customerMixin from '@/core/mixins/customerMixin'

const DEFAULT_CONFIG = {
    cores: 1,
    memory: 1,
    template: 'DEBIAN_10',
    hostname: '',
    interfaces: [],
    storages: []
}

export default {
    name: 'CloudServerCreateForm',
    components: { SliderEdit },
    mixins: [FormatValues, projectMixin, customerMixin],
    props: {
        value: {
            type: Object,
            default: () => DEFAULT_CONFIG
        },
        submitTitle: {
            type: String,
            default: 'Bestätigen'
        }
    },
    data: () => ({
        options: [],
        toggleNetworkList: false,
        freeIpAddresses: []
    }),
    computed: {
        networks () {
            return Network.all().filter(el => !el.is_public)
        },
        price () {
            let price = 0
            price += this.calculateOption('cores')
            price += this.calculateOption('memory')

            let disk_size = 0
            // eslint-disable-next-line array-callback-return
            this.value.storages.map(item => {
                disk_size += parseInt(item.size)
            })
            price += this.calculateOption('disk', disk_size)

            return price
        },
        templates() {
            return [
                {
                    title: 'Debian 9',
                    key: 'DEBIAN_9'
                },
                {
                    title: 'Debian 10',
                    key: 'DEBIAN_10'
                },
                {
                    title: 'Ubuntu 18.04',
                    key: 'UBUNTU_18.04'
                },
                {
                    title: 'Ubuntu 19.10',
                    key: 'UBUNTU_19.10'
                },
                {
                    title: 'Ubuntu 20.04',
                    key: 'UBUNTU_20.04'
                }
            ]
        },
        freeAddresses() {
            return IpAddress.query().where('reserved', null).where('type', null).get()
        }
    },
    methods: {
        addInterface() {
            this.value.interfaces.push({
                ipv4: [],
                primary_ipv4: null,
                is_public: true,
                has_firefall: false,
                network_id: undefined
            })
        },
        addStorage() {
            this.value.storages.push({ size: 10, replication: 2 })
        },
        async loadPricing () {
            const response = await this.api.getCloudServerPricing({ customer_id: this.customer.id, project_uuid: this.project.uuid })

            const memory = response.data.memory
            response.data.memory = {
                ...memory,
                min_units: memory.min_units / 1024,
                max_units: memory.max_units / 1024,
                step: memory.step / 1024
            }

            this.options = response.data

            // eslint-disable-next-line array-callback-return
            Object.values(this.options).map(el => {
                if (this.value[el.unit] !== undefined) {
                    // eslint-disable-next-line array-callback-return
                    return
                }

                this.value[el.unit] = el.min_units
            })
            this.change()
        },
        calculateOption (key, value) {
            const entry = this.options[key]
            if (entry === undefined) {
                return 99999
            }

            let units = (value || this.value[key]) - entry.free_units
            if (units < 0) {
                units = 0
            }

            return units / entry.step * entry.price
        },
        change () {
            const cloudServer = {
                interfaces: this.value.interfaces.map(item => {
                    return {
                        ...item,
                        ipv4: item.primary_ipv4 ? [item.primary_ipv4] : undefined
                    }
                }),
                storages: this.value.storages,
                template: this.value.template,
                hostname: this.value.hostname,
                title: this.value.hostname,
                fast: true,
                cores: this.value.cores,
                memory: this.value.memory,
                project_uuid: this.project.uuid,
                customer_id: this.customer.id, // FUCK whoever made the backend here (see CreateRequest line 39)
                id: this.value.id
            }

            this.$emit('input', cloudServer)
        },
        isStorageEdited (storage) {
            return storage.title && this.original.storages.find(elem => elem.title === storage.title).size !== parseInt(storage.size)
        }
    },
    created () {
        Network.fetchAll()
        IpAddress.fetchAll()
        this.loadPricing()
    }
}
</script>

<style scoped>
    .card {
        background: #eef0f8;
    }

    .custom-input {
        border: none;
        border-bottom: 1px solid black;
        border-radius: 0;
        background: transparent;
        font-size: 16px;
        padding: 0;
        height: calc(1.3em + 1rem);
    }
</style>
