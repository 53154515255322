<template>
    <div class="w-100 table-container h-100">
        <ProjectSidebar class="table-cell border-right" />
        <div v-if="project" class="rounded-0 table-cell min-w-250px p-2">
            <ProductList :project="project" @select="selectProduct" @add="showAdd" />
        </div>
        <b-card class="rounded-0 table-cell w-100 grey-background">
            <router-view />
        </b-card>
    </div>
</template>

<script>
import ProjectSidebar from '@/view/components/layout/ProjectSidebar'
import ProductList from '@/view/components/layout/ProductList'
import projectMixin from '@/core/mixins/projectMixin'

export default {
    name: 'CustomerDashboardLayout',
    components: { ProjectSidebar, ProductList },
    mixins: [
        projectMixin
    ],
    methods: {
        selectProduct(type, id) {
            alert(type + ' ' + id)
        },
        showAdd(type) {
            alert('Show add modal: ' + type)
        }
    }
}
</script>

<style scoped>
.table-cell {
  display: table-cell;
  vertical-align: top;
  height: 100%;
}

.table-container {
  display: table;
  height: 100%;
  width: 100%;
}

.grey-background {
  background: #E5E5E5;
}
</style>
